import React from "react";
import './home.scss';
import icon from "../../../img/icon3.png";
import result1 from "../../../img/result1.jpeg";
import result2 from "../../../img/result2.jpeg";
import kworks from "../../../img/kworks-logo.png";
import Diagram from "../../../img/Diagram.png";

export const Home = (): JSX.Element => {
    return(
        <div className="home">
            <div className="home__d1">
                <div className="home__d1__main">
                    <div className="home__d1__main__logo">
                         <div className="home__d1__main__logo__icon">
                            <img className="home__d1__main__logo__icon" src={icon} alt="Icon" />
                         </div>
                         <div className="home__d1__main__logo__text">
                            <div className="home__d1__main__logo__text__medusa"> Medusa Trade </div>
                            <div className="home__d1__main__logo__text__info"> AI Powered Trading System </div>
                         </div>
                    </div>
                </div>

                <div className="home__d1__img"></div>
            </div>

            <div className="home__d2">
                <div className="home__d2__title">Your benefits with Medusa AI:</div>
                <div className="home__d2__content">
                    <div className="home__d2__content__r1">
                        <div className="home__d2__content__r1__c1">
                            <div className="home__d2__content__r1__c1__title">
                                Automated Trading
                            </div> 
                            <div className="home__d2__content__r1__c1__p">
                            No need for manual trading or placing orders; all actions are automated with Medusa AI. All you need to do is watch your investment grow
                            </div>
                        </div>
                        <div className="home__d2__content__r1__c2">
                            <div className="home__d2__content__r1__c2__title">
                                Passive Income
                            </div> 
                            <div className="home__d2__content__r1__c2__p">
                            Medusa Trade offers its users a monthly return of 5% or more, resulting in nearly a 100% annual return, powered by its robust and reliable AI
                            </div>
                        </div>                    
                    </div>
                    <div className="home__d2__content__r2">
                        <div className="home__d2__content__r2__c1">
                            <div className="home__d2__content__r2__c1__title">
                                PAMM Account
                            </div> 
                            <div className="home__d2__content__r2__c1__p">
                            With the Medusa PAMM Account, you can access Medusa AI outputs and copy orders to your forex account fully automatically. In this way, your money is secured in your own account 
                            </div>
                        </div>
                        <div className="home__d2__content__r2__c2">
                            <div className="home__d2__content__r2__c2__title">
                                Affiliate Program
                            </div> 
                            <div className="home__d2__content__r2__c2__p">             
                            You can earn even more by sharing your affiliate link for MedusaTrade. You will receive a 10% commission on all profits generated by your referrals through MedusaTrade
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__d3">
                <div className="home__d3__border"></div>               
                <div className="home__d3__title">How It Works:</div>
                <div className="home__d3__content">
                    <div className="home__d3__content__p">
                        <p className="home__d3__content__p__p">
                        The short-term memory used by the algorithm is guided by the VotingClassifier model, which is trained with the results of 12 different machine learning models and the previous results stored in long-term memory
                        </p>
                    </div>
                    <div className="home__d3__content__results">
                        <img className="home__d3__content__results__result1" src={result1} alt="result1" />
                        <div className="home__d3__content__results__result2">
                           <p className="home__d3__content__results__result2__text">Results:</p>
                           <img className="home__d3__content__results__result2__img" src={result2} alt="result2" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__d4">
               <img className="home__d4__diagram" src={Diagram} alt="diagram" />
               <div className="home__d4__context">
                
               </div>
            </div>

            <div className="home__sponsors">
                <img className="home__sponsors__kworks" src={kworks} alt="icon" />
            </div>
        </div>
    );
};
import React from "react";
import './dev.scss';

export const Dev = (): JSX.Element => {
    return(
        <div className="dev">
            <div className="dev__coming">
                 COMING SOON . . .
            </div>
        </div>
    );
};
import './invest.scss';

const Performancegraph = () => {
    return (
      
       <iframe className="invest__r1__row1__col1__pergraf"
        src="https://staticmy.roboforex.com/en/informers/providers/frame/performance/352032/"
        title="Performance graph"
       />
      
    );
  };
  
export default Performancegraph;

import React from 'react';

const Largewidget = () => {
  return (
    <iframe className="invest__r1__row1__col2__narrow"
    src="https://staticmy.roboforex.com/en/informers/providers/frame/large/352032/"
    height="508"
    width="405"
    title="Large widget"
    />
  );
};

export default Largewidget;